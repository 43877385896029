<template>




  <div class="container has-background-white-ter">
    <section class="section is-primary pb-1">

      <h1 class="title is-2 is-spaced">
        Manage Files
      </h1>


      <div class="tile is-ancestor">
        <div class="tile is-parent">
          <article class="tile is-child box" v-for="image in images" :key="image.path">
            <p class="title">{{ image.title}}</p>



            <figure class="image  bg:white max-w:2xs max-h:2xs">
              <object  class="" :data="getSVGUrl(image.path)"></object>
            </figure>
            <button class="button" @click="changeImage(image.path)">Change {{ image.title }}</button>
          </article>

        </div>
      </div>




    </section>
    <svgSelector ref="svgSel" @okPressed="refreshImage" />
  </div>
</template>

<script>
//import firebase from 'firebase/compat/app';
//import 'firebase/compat/auth';

//import { toast } from "bulma-toast";
//import lunchHelper from "helpers/lunch"
//import { format } from "date-fns";
//import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
//import download from "downloadjs";
//import LunchOption from "./LunchOption.vue";
import svgSelector from "Components/school/filemanager/modalsvgselector.vue"




const images = [
  { title: "School Logo", path: "logo.svg"}

]

export default {
  name: "home",

  components: {
    svgSelector
  },

  data: function () {
    return {
      students: [],
      menu: [],
      lastUpdate: "",
      day: "",
      selectedOption: null,
      selectedOptions: {},
      placed: null,
      hashes: {},
      images
    };
  },

  computed: {




    currentDomain() {
      return this.$store.state.currentDomain;
    },
  },
  methods: {

    getSVGUrl(x) {
      let path = "https://assets.sense.services/"+this.currentDomain+'/'+x
      return path + (this.hashes[x] ? ("?"+this.hashes[x]) : "")
    },
    optionSelected(event, b) {
      this.selectedOption = event;
      this.selectedOptions = b;
    },

    changeImage(path) {
      this.$refs.svgSel.showModal("School Logo", path)

    },

    refreshImage(data) {
      this.hashes[data.path] = data.etag

      console.log(data)

    }


  },

  mounted() {

    const now = Date.now()
    this.images.forEach(x => {
      this.$set(this.hashes, x.path, now)
    })
  }


};
</script>
