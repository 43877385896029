<template>

  <div class="modal" :class="showModalFlag ? 'is-active' : '' ">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ title }}</p>
        <button class="delete" aria-label="close" v-on:click="cancelModal()">></button>
      </header>
      <section class="modal-card-body">
        <form enctype="multipart/form-data" novalidate v-if="isInitial">
          <h1>Upload Files (currently supports svg only)</h1>
          <div class="dropbox">
            <input type="file" :name="uploadFieldName" :disabled="isSaving"
              @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
              accept="image/svg+xml" class="input-file">
            <p>
              Drag your file here to begin<br> or click to browse
            </p>

          </div>
        </form>
        <div v-if="isConfirming"></div>
        <div ref="svgContainer"></div>
      </section>
      <footer class="modal-card-foot buttons is-right">
        <button v-if="isConfirming" class="button is-success" v-on:click="uploadModal()">Upload</button>
        <button class="button" v-on:click="cancelModal()">Cancel</button>
      </footer>
    </div>
  </div>

</template>

<script>
// swap as you need
// import { upload } from './file-upload.fake.service'; // fake service
// import { upload } from './file-upload.service';   // real service
// import { wait } from './utils';

//import { captureRejectionSymbol } from "events";
import { toast } from 'bulma-toast'

const parseDataURL = require("data-urls");

const STATUS_INITIAL = 0, STATUS_CONFIRM = 1, STATUS_SAVING = 2, STATUS_SUCCESS = 3, STATUS_FAILED = 4;
export default {
  name: 'app',
  components: {

  },

  data() {
    return {
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: 'inputFiles',
      csvBlob: null,
      csvHeaders: null,

      currentField: null,
      mapping: {},
      showModalFlag: false,
      okPressed: false,
      message: "",
      title: "",
      referenceData: null,
    }
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isConfirming() {
      return this.currentStatus === STATUS_CONFIRM;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
    currentOptions() {

      return this.mapping[this.currentField] || {}

    }
  },
  methods: {

    showModal: function (title, path) {
      this.okPressed = false;
      this.showModalFlag = true;
      this.currentStatus = STATUS_INITIAL
      this.$refs.svgContainer.innerHTML = ""

      this.title = title
      this.path = path
    },
    uploadModal: function () {
      this.okPressed = true;
      const that = this

      this.$api.get("v2/staff/files/public/" + this.path, true, true).then((x) => {
        return fetch(x.link, {
          body: that.$refs.svgContainer.innerHTML,
          method: 'put',
          headers: {
            'Content-Type': "image/svg+xml"
          }
        }).then( x => {

          if (x.status === 200) {
            toast({
                  message: ("The "+this.title+" has been uploaded"),
                  type: 'is-success',
                  duration: 5000,
                  closeOnClick: true,
            })
            this.$emit("okPressed", {path: this.path, etag: x.headers.get("etag")})
            this.showModalFlag = false;
            return true
          }

          toast({
                  message: ("The upload failed with error code: ".x,status.toString()),
                  type: 'is-danger',
                  duration: 20000,
                  closeOnClick: true,
                  position: 'center'
            })

            return false



        }).then (x => console.log(x))



      })
      //this.$emit("okPressed", this.referenceData)
      //this.showModalFlag = false;
    },
    cancelModal: function () {
      this.okPressed = false;
      this.$emit("cancelPressed")
      this.showModalFlag = false;
    },








    async filesChange(fieldName, fileList) {
      // handle file changes

      if (fieldName != this.uploadFieldName) return;

      if (!fileList.length) return;

      console.log(fileList)

      const that = this

      const reader = new FileReader();
      reader.addEventListener('load', (event) => {

        const x = new TextDecoder("utf-8").decode(parseDataURL(event.target.result).body)
        that.$refs.svgContainer.innerHTML = x
        this.currentStatus = STATUS_CONFIRM
      });
      reader.readAsDataURL(fileList[0]);

    }
  },
  mounted() {

    this.instance = this;
  },
}


</script>

<style>
.dropbox {
  outline: 2px dashed grey;
  /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px;
  /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0;
  /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue;
  /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}
</style>